import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  const [show, setshow] = useState(false)
  const [active, setActive] = useState('/');

  const toggle=()=>{
    setshow(!show)
  }
  return (
    <div>
      <div className='Desktop' >
      <div style={{display:"flex",justifyContent:'space-around',alignItems:"center",padding:10,background:'#f8f4eb'}}>
      <Link className='link' to={"/"}><h2 style={{color:"#e05959d6",fontFamily:"ui-monospace"}}>Duniyape Exports</h2></Link>
        <ul style={{textTransform:"uppercase",display:"flex",listStyleType:"none",justifyContent:"center",alignItems:"center",gap:20,fontWeight:500}}>
            <li  onClick={() =>setActive('/')} ><Link className={active==="/"?'active':'nav link'} to="/">Home</Link></li> 
            <li onClick={() => setActive('/About')} ><Link className={active==="/About"?'active':'nav link'} to="/About">About</Link></li>
            <li onClick={() => setActive('/Product')} className={active==="/Product"?'active':'nav'}>Product</li> 
            <li onClick={() => setActive('/Quality')} className={active==="/Quality"?'active':'nav'}>Quality Assurance</li>
            <li onClick={() => setActive('/Contact')} className={active==="/Contact"?'active':'nav'}>Contact</li>
            <li className='button' style={{border:"1px solid red" ,borderRadius:15,padding:'5px 15px',color:"#dc143c"}}>Track your order</li>
            {/* <li ><div style={{border:"1px solid #00000070" ,borderRadius:15,padding:'0px 15px'}}><input style={{outline:'none',border:"none",padding:8,background:"transparent"}}  placeholder='Search' type='search'/><i  className="fa-solid fa-magnifying-glass"/></div></li> */}
        </ul>
        </div>
        <hr></hr>
      </div>
      <div className='Mobile'>
        <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",padding:"10px 15px"}}>
          <Link className='link' to={"/"}><h3 style={{color:"#e05959d6",fontFamily:"ui-monospace"}}>Duniyape Exports</h3></Link>
          {/* <div style={{border:"1px solid #00000070" ,borderRadius:15,padding:'0px 15px',display:"flex",alignItems:"center "}}><input style={{outline:'none',border:"none",width:120,padding:8,background:"transparent"}}  placeholder='Search' type='search'/><i  className="fa-solid fa-magnifying-glass"/></div> */}
          <i onClick={toggle} style={{fontSize:20}} className={show?'fa fa-xmark':'fa fa-bars'}/>
        </div>
        <hr/>
        {show&&<><ul style={{textTransform:"uppercase",padding:0,display:"flex",flexDirection:"column",listStyleType:"none",justifyContent:"center",alignItems:"center",gap:20,fontWeight:500}}>
            <li onClick={() =>setActive('/')} ><Link className={active==="/"?'active':'nav link'} to="/">Home</Link></li> 
            <li onClick={() => setActive('/About')} ><Link className={active==="/About"?'active':'nav link'} to="/About">About</Link></li>
            <li onClick={() => setActive('/Product')} className={active==="/Product"?'active':'nav'}>Product</li> 
            <li onClick={() => setActive('/Quality')} className={active==="/Quality"?'active':'nav'}>Quality Assurance</li>
            <li onClick={() => setActive('/Contact')} className={active==="/Contact"?'active':'nav'}>Contact</li>
            <li className='button' style={{border:"1px solid red" ,borderRadius:15,padding:'5px 15px',color:"#dc143c"}}>Track your order</li>
        </ul>
        <hr/>
        </>}
      </div>
    </div>
  )
}

export default Navbar
