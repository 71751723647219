import React from 'react'
import { Link } from 'react-router-dom';


const Spices = () => {

  const category = [
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/spices.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2018/03/spices.png',
      name: 'Spices',
      Link:"/Spices"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/oilseeds.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2018/03/spices.png',
      name: 'Oil Seeds',
      Link:"/Oilseeds"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/pulses.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2017/12/icon-pulses.png',
      name: 'Pulses',
      Link:"/Pulses"
    },
    {
      img:    'https://www.nkagro.com/wp-content/uploads/2017/12/others.png',
      logo: 'https://www.nkagro.com/wp-content/uploads/2018/03/others.png',
      name: 'Fresh Vegetables',
      Link:"/Vegetables"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/others-1.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2017/12/other-icon.png',
      name: 'Others',
      Link:"/Others"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/welcome-marine.jpg',
      logo:'https://www.nkagro.com/wp-content/uploads/2017/12/icon-marine.png',
      name: 'Marine',
      Link:"/Marine"
    },
];
  return (
    <div className="spices" style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        {/*Banner */}
    <div style={{width:"100vw",position:"relative"}}>
    <img className='banner' width={"100%"} style={{objectFit:"cover"}} src='https://www.nkagro.com/wp-content/uploads/2018/03/spice-banner.png'></img>
    <div style={{width:"100%",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",display:'grid',placeItems:'center'}}>
      <div data-aos="zoom-in" style={{width:"50%",textAlign:"center"}}>
          <h1  style={{fontSize:"3.5vw",color:"white"}}>Spices</h1>
      </div>
    </div>
    </div>

<div className='w100' style={{width:"60%"}}>
{/*Text */}
  <div style={{textAlign:"center",padding:20}}>
      <h3 style={{fontSize:"2.5vw"}}>Duniyape have been one of the leading spice exporters for over a decade and export various varieties of high grade spices.</h3>
      <p >A spice adds flavour, aroma and colour to our food. Most spices have various medicinal properties and hence used in the preparation of medicines from ancient times. They are also used as as in ingredient in cosmetics and perfumes.</p>
  </div>

  {/*Products */}
  <div style={{display:"grid",gridTemplateColumns:'repeat(auto-fit, minmax(150px, 1fr))',gap:20,padding:20,alignItems:"center"}}>
  {category.map(item=>(<Link to={item.Link}><div data-aos="zoom-in" style={{position:"relative",overflow:"hidden",cursor:'pointer'}}>
  <img className='zoomIn' style={{width:" 100%",zIndex:1, height: 'auto',objectFit: 'cover',transition:"transform 0.3s ease-in-out"}} src={item.img}/>
  <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",textAlign:"center",display:'flex',justifyContent:"center",flexDirection:'column'}}>
  <img src={item.logo}/>
  <span  style={{color:"white"}}>{item.name}</span>
  </div>
</div></Link>))}
  </div>

</div>




    </div>
  )
}

export default Spices
