import React from 'react'
import { Link } from 'react-router-dom';

const Categories = () => {
  const category = [
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/spices.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2018/03/spices.png',
      name: 'Spices',
      Link:"/Spices"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/oilseeds.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2018/03/spices.png',
      name: 'Oil Seeds',
      Link:"/Oilseeds"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/pulses.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2017/12/icon-pulses.png',
      name: 'Pulses',
      Link:"/Pulses"
    },
    {
      img:    'https://www.nkagro.com/wp-content/uploads/2017/12/others.png',
      logo: 'https://www.nkagro.com/wp-content/uploads/2018/03/others.png',
      name: 'Fresh Vegetables',
      Link:"/Vegetables"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/others-1.png',
      logo:'https://www.nkagro.com/wp-content/uploads/2017/12/other-icon.png',
      name: 'Others',
      Link:"/Others"
    },
    {
      img:'https://www.nkagro.com/wp-content/uploads/2017/12/welcome-marine.jpg',
      logo:'https://www.nkagro.com/wp-content/uploads/2017/12/icon-marine.png',
      name: 'Marine',
      Link:"/Marine"
    },
];
  return (
    <div  style={{width:"100%",display:'flex',justifyContent:"center"}}>
        <div className='category'  style={{width:"60%",display:"grid",gridTemplateColumns:"1fr 2fr",margin:"3% 0"}}>
            <div style={{width:"100%"}}>
              <div style={{padding:20}}>
                <h1 >PRODUCTS</h1>
                <h3 style={{marginTop:-10}}>BY CATEGORIES</h3>
                <p style={{textAlign:'justify'}}>Duniyape Exports has built it’s reputation over the years with a huge customer base worldwide. We offer a wide range of products without compromising on the quality. On time delivery to our global clients is highly prioritised, hence we work round the clock for speedy completion of orders.</p>
                </div>
                <div  style={{textAlign:"center"}}>
                <img style={{width:"70%"}} src='https://www.nkagro.com/wp-content/uploads/2017/12/home-product.jpg'/>
                </div>
            </div>
            <div   style={{display:"grid",gridTemplateColumns:'repeat(auto-fit, minmax(150px, 1fr))',gap:20,padding:20,alignItems:"center"}}>
              {category.map(item=>(<Link to={item.Link}><div data-aos="zoom-in" style={{position:"relative",overflow:"hidden",cursor:'pointer'}}>
                <img className='zoomIn' style={{width:" 100%",zIndex:1, height: 'auto',objectFit: 'cover',transition:"transform 0.3s ease-in-out"}} src={item.img}/>
                <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",textAlign:"center",display:'flex',justifyContent:"center",flexDirection:'column'}}>
                <img src={item.logo}/>
                <span  style={{color:"white"}}>{item.name}</span>
                </div>
              </div></Link>))}
            </div>
        </div>
      
    </div>
  )
}

export default Categories
