import React from 'react'
import Categories from '../Components/Categories'
import Procured from '../Components/Procured'
import Awards from '../Components/Awards'
import Quality from '../Components/Quality'
import Banner from '../Components/Banner'

const Home = () => {
  return (
    <div>
      <Banner/>
      <Categories/>
      <Procured/>
      <Awards/>
      <Quality/>
    </div>
  )
}

export default Home
