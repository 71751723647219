import React from 'react'

const Footer = () => {
  return (
    <div style={{padding:20,background:`url(${'https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/foot-bg.png'}) repeat`,color:"#cccccc"}}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <p>	&#169;2024 Duniyape Exports (India) Pvt Ltd</p>
            <p>	Website Design by Pankaj Sahu</p>
        </div>
        <ul style={{display:"flex",listStyleType:"none",margin:0,padding:0,alignItems:"center",flexWrap:"wrap"}}>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>About</li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>Product</li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>Quality Assurance</li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>Careers</li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>News And Announcement</li>
            <li style={{borderRight:"1px solid grey",padding:"0 10px"}}>Contact</li>
            <li style={{padding:"0 10px"}}>Sitemap</li>
             </ul>
      
    </div>
  )
}

export default Footer
