import React, { useState } from 'react';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [enquiry, setEnquiry] = useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Here you can add logic to send the form data to a server
      // using a method like fetch or axios
      console.log('Form submitted:', { name, email, phone, city, enquiry });
    };
  
    return (
      <div style={{width:"100%", backgroundColor: '#f2c54e', padding:'20px 0',display:'flex',justifyContent:"center" }}>
        <form data-aos="zoom-in" className='w100' style={{width:"60%",padding:20}} onSubmit={handleSubmit}>
        <h1 style={{textAlign:"center"}}>Have a query on our products?</h1>
          <div className='mobile' style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:20}}>
            <div style={{ marginRight: '20px',display:"flex",alignItems:'center',marginBottom:15}}>
                <span style={{ marginRight: '5px', color: '#DC143C' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.87 9.768 10 9.002c-.87-.766-1.591-1.409-2.222-2.023-.631-.614-1.255-1.227-2.222-2.023-.77-.734-1.48-1.29-2.222-2.023-.731-.715-1.375-1.419-2.222-2.023-.87-.766-1.592-1.41-2.222-2.023C.059 5.51 0 5.264 0 5.002c0-.264.059-.51.168-.771C.411 4.021 1.115 3.502 2 3.002c.886-.5 1.803-.998 2.748-1.457C5.48 1.52 6.286 1 7 1s1.52.52 2.748 1.457C10.48 2.002 11.29 2.5 12 3.002.832 4.51 1.168 5.264 1.168 5.002c0 .264-.059.51-.168.771C.589 6.021 .875 6.502 1 7c-.77.734-1.48 1.29-2.222 2.023-.631.614-1.255 1.227-2.222 2.023-.731.715-1.375 1.419-2.222 2.023-.87.766-1.592 1.41-2.222 2.023C.059 11.51 0 11.764 0 12c0 .264.059.51.168.771C.411 12.979 1.115 13.498 2 14.002c.886.5 1.803.998 2.748 1.457C5.48 15.48 6.286 16 7 16s1.52-.52 2.748-1.457C10.48 14.998 11.29 14.498 12 14.002c.832-.51 1.168-.771 1.168-5.002z" />
                  </svg>
                </span>
              <input
                type="text"
                placeholder='Name'
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                style={{ width: '100%', padding: '10px',outline:"none",border:"none",background:"transparent",borderBottom:"1px solid black"}}
              />
            </div>

            <div style={{display:"flex",alignItems:'center',marginRight: '20px' ,marginBottom:15}}>
                <span style={{ marginRight: '5px', color: '#DC143C' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034-2.868L13.71 4.553 13 2.572l-3.5 2.034L5.5 2.572l-3.5 2.034L1.286 4.553l-.034 2.868L5.108 11l4.708-2.825L15 11.105V5.383Z" />
                  </svg>
                </span>
              <input
                type="email"
                placeholder='Email'
                id="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                style={{ width: '100%', padding: '10px',outline:"none",border:"none",background:"transparent",borderBottom:"1px solid black"}}

              />
            </div>
          </div>
          <div className='mobile' style={{display:"grid",gridTemplateColumns:"1fr 1fr",gap:20}}>
            <div style={{ marginRight: '20px',display:"flex",alignItems:'center',marginBottom:15 }}>
                <span style={{ marginRight: '5px', color: '#DC143C' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.32c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0 .063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.498-.697a.678.678 0 0 0-.68-.58l-.696-2.498A.678.678 0 0 0 1.328 3.654l1.794-2.307a.678.678 0 0 0 .122-.58Z" />
                    <path d="M14.553.063a.678.678 0 0 1 .063-1.015l2.307 1.794a.678.678 0 0 1 .58.122l2.498.697a.678.678 0 0 1 .68.58l.696 2.498A.678.678 0 0 1 16.938 3.654l-1.794 2.307a.678.678 0 0 1-.58.122l-2.498.697a.678.678 0 0 1-.68.58l-.697 2.498a.678.678 0 0 1-.122.58L11.553 14.937a.678.678 0 0 1-1.015.063l-2.307-1.794a.678.678 0 0 1-.122-.58Z" />
                  </svg>
                </span>
              <input
                type="tel"
                id="phone"
                placeholder='+91'
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                style={{ width: '100%', padding: '10px',outline:"none",border:"none",background:"transparent",borderBottom:"1px solid black"}}
              />
            </div>

            <div style={{display:"flex",alignItems:'center',marginRight: '20px',marginBottom:15 }}>
                <span style={{ marginRight: '5px', color: '#DC143C' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.49 31.49 0 0 1 8 14.58a31.48 31.48 0 0 1-1.96-3.07C3.304 7.867 2.38 6.808 1.611 5.525c-.77-1.284-.275-3.012.933-3.785.47- .252 1.04-.402 1.609-.417.59-.015 1.18.165 1.609.417 1.208.773 1.705 2.501.933 3.785-.769 1.282-1.694 2.303-2.933 3.083Zm-.803.121-.707.707L3 11.707l.707.707L11.359 13.4L12.166 12.609ZM10.848 2.511a.5.5 0 1 1 .002.999.5.5 0 0 1-.002-.999z" />
                  </svg>
                </span>
              <input
                type="text"
                id="city"
                placeholder='City'
                value={city}
                onChange={(event) => setCity(event.target.value)}
                style={{ width: '100%', padding: '10px',outline:"none",border:"none",background:"transparent",borderBottom:"1px solid black"}}
              />
            </div>
          </div>
          <div style={{ marginTop: '10px',marginRight: '40px'  }}>
            <label htmlFor="enquiry" style={{ display: 'block', marginBottom: '5px',color:"#888680" }}>
              Enquiry
            </label>
            <textarea
              id="enquiry"
              value={enquiry}
              onChange={(event) => setEnquiry(event.target.value)}
              style={{ width: '100%', padding: '10px',outline:"none",border:"none",background:"transparent",borderBottom:"1px solid black",height:50}}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <input type="checkbox" id="robot-check" />
                <label htmlFor="robot-check" style={{ marginLeft: '5px' }}>
                  I'm not a robot
                </label>
              </div>
              <div>
                <img
                  src="https://www.google.com/recaptcha/api2/anchor?ar=1&hl=en&size=invisible&badge=bottomright&v=0qN4j56gO9_9k233r5F4gW0&theme=light&k=6Lcku_S4AAAAAAB853-4-W11kC9nW4YcQ7d8-v9-wC4K1C2vYz6-56b7n7lB68C5gYQ7-6-1h35329d8_6696626479&co=aHR0cHM6Ly93d3cuZXhhbXBsZS5jb20v&hl=en&d=aHR0cHM6Ly93d3cuZXhhbXBsZS5jb20v&type=image"
                  alt="reCAPTCHA"
                  style={{ width: '100px', height: '30px', cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
          <button type="submit" style={{ backgroundColor: '#DC143C', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '20px' }}>
            Submit
          </button>
        </form>
      </div>
    );
  };

export default Contact
