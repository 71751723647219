import React from 'react'

const Procured = () => {
  return (
    <div style={{width:"100%"}}>
        <div  style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:-50}}>
             <img data-aos="zoom-in" alt="loading..." className='spoon'   src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/spoon.jpg'/>
        </div>
      <div className='procured' style={{padding:'40px 0',width:"100%" ,background:"#fbf5ea",display:"grid",gridTemplateColumns:"1fr 1fr 1fr"}}>
        <div  style={{display:'flex',justifyContent:'space-around',flexDirection:"column",alignItems:"center",gap:20}} >
            <img alt="loading..."  data-aos="zoom-in"  style={{width:"70%"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-groundnut.jpg'/>
            <img alt="loading..." data-aos="zoom-in" style={{width:"70%"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-rice.jpg'/>
        </div>
        <div  style={{display:'flex',justifyContent:'flex-end',flexDirection:"column",alignItems:"center"}} >
            <div data-aos="zoom-in" style={{textAlign:"center"}}>
                <h1 style={{fontSize:"3vh"}}>Procured fromthe finest producers…</h1>
                <h3 style={{fontSize:"2vh",textTransform:'uppercase',color:'#0000006e'}}>Packed and dispatched with perfection.</h3>
            </div>
            <img alt="loading..." data-aos="zoom-in" style={{width:"70%"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-chillies.jpg'/>

        </div>
        
        <div  style={{display:'flex',justifyContent:'space-around',flexDirection:"column",alignItems:"center",gap:20}} >
            <img alt="loading..." data-aos="zoom-in" style={{width:"70%"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-turmeric.jpg'/>
            <img alt="loading..." data-aos="zoom-in" style={{width:"70%"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-cumin-seeds.jpg'/>
        </div>
      </div>
    </div>
  )
}

export default Procured
