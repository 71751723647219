import React from 'react'

const Banner = () => {
  return (
    <div style={{position:"relative"}}>
      <img className='banner' width={"100%"} style={{objectFit:"cover"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-band.jpg'></img>
      <div style={{width:"100%",position:"absolute",top:"40%",left:"50%",transform:"translate(-50%,-50%)",display:'grid',placeItems:'center'}}>
        <div data-aos="zoom-in" style={{width:"50%",textAlign:"center"}}>
            <h1  style={{fontSize:"3.5vw"}}>Welcome to Duniyape Exports</h1>
            <h3 style={{fontSize:"2.5vw",color:"#00000070"}}>One of the largest Exporter</h3>
        </div>
    </div>
    </div>
  )
}

export default Banner
