import './App.css';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import { createBrowserRouter, RouterProvider,Outlet } from 'react-router-dom';
import Spices from './Pages/Spices';
import Home from './Pages/Home';
import Vegetables from './Pages/Vegetables';
import Others from './Pages/Others';
import Marine from './Pages/Marine';
import Pulses from './Pages/Pulses';
import Oilseeds from './Pages/Oilseeds';
import About from './Pages/About';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <div className="App">
        <Navbar />
        <div className="content">
          {/* Add a Outlet component here to render the route elements */}
          <Outlet />
        </div>
        <Contact />
        <Footer />
      </div>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "Spices",
        element: <Spices />,
      },
      {
        path: "Vegetables",
        element: <Vegetables />,
      },
      {
        path: "Others",
        element: <Others />,
      },
      {
        path: "Marine",
        element: <Marine />,
      },
      {
        path: "Pulses",
        element: <Pulses />,
      },
      {
        path: "Oilseeds",
        element: <Oilseeds />,
      },
      {
        path: "About",
        element: <About />,
      },
    ],
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

