import React from 'react'

const Awards = () => {
  return (
    <div style={{width:"100%",display:'flex',justifyContent:"center"}}>
        <div className='w100 Award' style={{width:"60%",margin:"5% 0",padding:20}}>
            <h1>Awards & Accreditations</h1>
            <div className='mobile' style={{width:"100%",display:"grid",gridTemplateColumns:"1fr 1fr",margin:"3% 0"}}>
                <div data-aos="zoom-in" style={{padding:20,borderRight:"1px solid #80808040    "}}>
                    <img src='https://www.nkagro.com/wp-content/uploads/2017/12/award2.jpg'/>
                    <h3 >Spices Board India</h3>
                    <p>We have genuinely acquired all the necessary certifications required in an authentic manner for the export of all spices from the Spices Board of India</p>
                </div>
                <div data-aos="zoom-in" style={{padding:20}}>
                    <img src='https://www.nkagro.com/wp-content/uploads/2017/12/award4.jpg'/>
                    <h3 >APEDA Export Award</h3>
                    <p>Duniyape is a proud recipient of the APEDA award which is very prestigious in the field of agriculture. We will continue to serve our clients with the best quality products.</p>
                </div>
            </div>
            <div style={{display:'flex',justifyContent:"center"}}>
                <div data-aos="zoom-in" className='button' style={{padding:"5px 10px",color:"#dc143c",border:"1px solid #dc143c",borderRadius:"15px"}}>Learn More</div>
            </div>
        </div>
      
    </div>
  )
}

export default Awards
