import React from 'react'

const Quality = () => {
  return (
    <div style={{width:"100%",display:'flex',justifyContent:"center",background:`url(${'https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/home-chilly-bg.jpg'}) no-repeat center bottom`,backgroundSize:"cover",padding:"70px 0px 200px"}}>
      <div className='w100 mobile' style={{width:"60%",display:"grid",gridTemplateColumns:"1fr 1fr",margin:"3% 0",gap:20,padding:20}}>
                <div data-aos="zoom-in" style={{padding:40,background:"white",margin:'20px 0'}}>
                    <img src='https://www.nkagro.com/wp-content/uploads/2017/12/icon-quality-focus.png'/>
                    <h3 >Our Quality Focus</h3>
                    <p>We are committed to supply quality and fresh produce to our customers and enhance the customer satisfaction levels through continual improvement in our process and to become leader in the market.</p>
                    <div style={{display:'flex'}}>
                        <div className='button' style={{padding:"5px 10px",color:"#dc143c",border:"1px solid #dc143c",borderRadius:"15px"}}>Learn More</div>
                    </div>
                </div>
                <div data-aos="zoom-in" style={{padding:40,background:"white",margin:'20px 0'}}>
                    <img src='https://www.nkagro.com/wp-content/uploads/2017/12/icon-harvest-calendar.png'/>
                    <h3 >Harvest Calendar</h3>
                    <p>The harvest calendar provides you with details on what crop grows in each month round the year. Some crops are available throughout the year whereas some are seasonal. The harvest schedule helps consumer make purchase decisions efficiently.</p>
                    <div style={{display:'flex'}}>
                        <div className='button' style={{padding:"5px 10px",color:"#dc143c",border:"1px solid #dc143c",borderRadius:"15px"}}>Learn More</div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Quality
