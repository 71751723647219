import React from 'react'

const About = () => {
    return (
        <div className="spices" style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
            {/*Banner */}
        <div style={{width:"100vw",position:"relative"}}>
        <img  className='banner' width={"100%"} style={{objectFit:"cover"}} src='https://www.nkagro.com/wp-content/uploads/2018/02/top_about.jpg'></img>
        <div style={{width:"100%",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",display:'grid',placeItems:'center'}}>
          <div data-aos="zoom-in" style={{width:"50%",textAlign:"center"}}>
              <h1  style={{fontSize:"3.5vw",color:"white"}}>Who Are We?</h1>
              <h3  style={{color:"white"}}>Spices specialists for over a decade</h3>
          </div>
        </div>
        </div>

        <div style={{width:"100vw",position:"relative"}}>
        <img  className='banner' width={"100%"} style={{objectFit:"cover"}} src='https://www.nkagro.com/wp-content/themes/nkexpo/inc/images/about_bg.png'></img>
        <div className='position' style={{position:"absolute",top:"50%",left:"0%",transform:"translate(0%,-50%)",marginLeft:"5%"}}>
          <div className='w100' style={{width:"50%"}}>
          <div style={{textAlign:"center",padding:15}}>
              <h1 >India is an Agricultural Country.</h1>
              <p style={{textAlign:'justify'}} >The principal occupation among the Indian masses is cultivation of crops and other farm related activities. India takes pride in being the second largest economy in the production of agricultural materials.</p>
          </div>
          </div>
        </div>
        </div>

        <div className='w100' style={{width:"60%"}}>
              <div style={{padding:20}}>
                <img className='w100' style={{width:"60%"}} src='https://www.nkagro.com/wp-content/uploads/2018/02/garlic-product.png'/>
                <p style={{textAlign:'justify'}}>Duniyape  is one of the biggest spices exporters of India. We export a wide range of agricultural products. Dry chillies, Groundnut, Cummins, Fennel, Rice, etc are some of the commodities we export on a large scale.</p>
                <p style={{textAlign:'justify'}}>We also have a cold storage facilities located in Madhya Pradesh and Andhra Pradesh which are used to store spices. It is a modern cold chain solution with latest technology to keep the products fresh.</p>
                </div>
                <div  style={{textAlign:"center"}}>
                </div>
            </div>

    
        </div>
      )
}

export default About
